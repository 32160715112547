// Print
// -----

@media print {
  * {
    font-weight: normal !important;
    color: black !important;
  }

  body {
    font-size: 10pt;
  }

  // 'display: flex' breaks paging
  body,
  .page-content {
    display: block;
  }

  .page-header,
  .erratic-header,
  .page-footer,
  .erratic-footer,
  .erratic-form,
  .divide {
    display: none;
  }

  .post {
    margin: 0 auto;
  }

  .post--image,
  .page-content blockquote {
    break-inside: avoid;
  }

  .copy {
    a {
      text-decoration-thickness: 1pt;
      text-decoration-color: black;
    }
  }
}
