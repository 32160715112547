// Mixins
// ------

// Respond
// --------------------------
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
@mixin respond($size, $operator: min, $aspect: width) {
  @media all and (#{$operator}-#{$aspect}: baseEm($size)) {
    @content;
  }
}

// Block
// --------------------------
@mixin block($width: $contentWidth, $mode: max-width) {
  @if $mode == max-width {
    width: 100%;
    max-width: em($width);
  } @else if $mode == width {
    width: em($width);
    max-width: 100%;
  }

  padding-right: em($shim);
  padding-left: em($shim);
}

// Grid
// --------------------------
@mixin grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ($space * 0.618);

  img {
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  @include respond($michigan) {
    grid-template-columns: 1fr 1fr;

    .is-unbroken {
      grid-column: span 2;
    }
  }
}

// Text styles
// --------------------------
@mixin text-button {
  font-family: $sans;
  font-size: $beta;
  line-height: $snugLineHeight;
}

@mixin text-head {
  font-size: $alpha;
  font-weight: $regular;
  line-height: $lessLineHeight;
  hyphens: manual;
}

// Links
// --------------------------
@mixin fancy($color: var(--hueLight), $solid: false) {
  text-decoration: underline;
  text-decoration-color: $color;
  text-underline-offset: em(3);
  text-decoration-thickness: $borderThickness;
  transition: text-decoration-color $duration $timing;
  // TODO remove once settled
  // $scopeDuration: ($duration * 2);
  // border-radius: $borderRadius;
  // transition: background-color $scopeDuration $timing, text-decoration-color $scopeDuration $timing, text-underline-offset $scopeDuration $timing;

  &:hover {
    text-decoration-color: var(--hue);
    // text-decoration-color: transparent;
    // text-underline-offset: em(4);
    // background-color: var(--hueLighter);
  }

  @if $solid != false {
    @include unfancy;

    color: $color;
  }
}

@mixin sturdy($color: var(--hue)) {
  font-family: $sans;
  font-weight: $semibold;
  color: $color;
}

// this has to override fancy styles above
@mixin unfancy {
  text-decoration: none;

  // &:hover {
  //   background-color: transparent;
  // }
}

// Images
// --------------------------
@mixin mono {
  &.is-mono {
    img {
      mix-blend-mode: multiply;

      @media (prefers-color-scheme: dark) {
        mix-blend-mode: screen;
      }
    }
  }
}

// Rule
// --------------------------
@mixin rule($color: var(--hueLight)) {
  width: 8.7em;
  border: none;
  border-top: $borderThickness solid $color;
  margin: 2em auto;
}

// Corner fill
// --------------------------
@mixin corner-fill {
  position: relative;

  &::before {
    content: "";
    background-color: var(--borderColor);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

// Form
// --------------------------
@mixin button($verticalPadding, $horizontalPadding) {
  @include text-button;

  display: block;
  font-weight: $semibold;
  text-align: center;
  color: var(--bgColor);
  background-color: var(--buttonColor);

  padding: $verticalPadding $horizontalPadding;
  border: none;
  border-radius: $borderRadius;
  cursor: pointer;

  &,
  .copy & {
    text-decoration: none;

    transition: background-color $duration $timing;
  }

  &:hover,
  &.is-active {
    background-color: var(--buttonFocus);
  }

  &:focus,
  &.is-active {
    // TODO adjust color
    box-shadow: inset 0 0 0 $borderThickness var(--borderColor);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: var(--softColor);
    cursor: auto;
  }
}

@mixin form-pieces {
  [type=text],
  [type=email],
  textarea {
    display: block;
    width: 100%;

    font-family: $sans;
    font-size: $beta;
    color: var(--textColor);

    background-color: var(--bgColor);
    padding: 0 0.4em 0;
    border: none;
    border-radius: $borderRadius;

    transition: box-shadow $duration $timing;

    &:focus {
      box-shadow: 0 0 0 $borderThickness var(--borderColor);
      outline: 0;
    }
  }

  [type=text],
  [type=email] {
    height: em(48, 20);
  }

  [type=submit] {
    font-size: $beta;
    background-color: transparent;
    padding: 0 0.25em;
    border: 0;
    border-radius: $borderRadius;
    cursor: pointer;

    &:focus {
      text-shadow: 0 0 0.25em var(--borderColor), 0 0 0.5em var(--borderColor);
    }

    &:focus-visible {
      text-shadow: none;
      box-shadow: 0 0 0 $borderThickness var(--borderColor), inset 0 0 0 1em var(--borderColor);
      outline: none;
    }
  }
}

