// Constants
// ---------

// Colors
// --------------------------------------------------------
$bgSol: hsl(48 100% 98%);
$toneSol: hsl(45 27% 94%);
$softSol: hsl(45 6% 68%);
$subtleSol: hsl(48 4% 56%);
$somberSol: hsl(48 8% 33%);
$textSol: hsl(48 8% 10%);

$bgLuna: hsl(261 16% 14%);
$toneLuna: hsl(264 10% 18%);
$softLuna: hsl(264 8% 48%);
$subtleLuna: hsl(261 7% 69%);
$somberLuna: hsl(261 8% 76%);
$textLuna: hsl(261 8% 93%);

// Typography
// --------------------------------------------------------
$serif: "vega-webfont", serif;
$sans: "source-sans-webfont", sans-serif;

$emBase: 22;

$alpha: (math.div(29, $emBase) * 1em);
$beta: (math.div(20, $emBase) * 1em);
$gamma: (math.div(28, 29) * 1em);

$regular: 400;
$semibold: 600;

// Measurements
// --------------------------------------------------------
$contentWidth: 720;
$headerWidth: 800;
$formWidth: 202;

$baseLineHeight: calc(var(--lead) / #{$emBase});
$snugLineHeight: 1.375;
$lessLineHeight: 1.31;

$space: 1.65em;
$shim: 12;
$borderThickness: 0.125rem;
$borderRadius: 0.125rem;

$duration: 0.04s;
$durationYear: 168s;
$timing: ease-out;

// Responsive breakpoints
// --------------------------------------------------------
$erie: 460;
$michigan: 610;
$huron: 720;
$victoria: 912;
$superior: 1200;
