// Base
// ----

// apply a natural box layout model to everything
*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background: var(--hueLighter);
}

// Global
// --------------------------------------------------------
html,
body,
button,
textarea,
input,
select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  height: 100%;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  // NB this only works in Safari for now (11/23)
  hanging-punctuation: first allow-end last;

  // TODO test
  scroll-behavior: smooth;
  scroll-padding-top: rem(30);
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100%;

  font-family: $serif;
  // this is 18px
  font-size: 1.125em;
  font-weight: $regular;
  line-height: $baseLineHeight;
  color: var(--textColor);

  background-color: var(--bgColor);
  margin: 0;

  @include respond($erie) {
    // this is 20px
    font-size: (math.div(20, 16) * 1em);
  }

  @include respond($michigan) {
    // this is 22px
    font-size: (math.div($emBase, 16) * 1em);
  }

  @media (prefers-color-scheme: dark) {
    // TODO is this a good idea?
    letter-spacing: 0.005em;
  }
}

// Typography
// --------------------------------------------------------
h1,
h2,
h3,
h4 {
  @include text-head;

  margin: 0;
}

h1 {
  .copy & {
    $proportion: math.div(1em, $alpha);

    margin-bottom: ($proportion * 1em);
  }
}

h2 {
  .post .copy &:not(:first-child) {
    $proportion: math.div(1em, $alpha);

    margin: ($proportion * $space) 0 ($proportion * math.div($space, 2)) 0;
  }

  .post__narrow & {
    $proportion: math.div(1em, $alpha);

    margin-bottom: ($proportion * 1em);
  }
}

p {
  margin: 0 0 1em;
}

blockquote {
  margin: 0;
}

figure {
  margin: 0;
}

img {
  @media (prefers-color-scheme: dark) {
    filter: brightness(0.92) contrast(1.1);
  }
}

a,
[type=submit] {
  color: inherit;
  text-decoration: none;
}

a {
  svg {
    fill: currentColor;
    vertical-align: bottom;
  }
}

[type=submit] {
  @include sturdy;

  line-height: $baseLineHeight;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

input,
textarea {
  caret-color: var(--hueDarker);

  &::placeholder {
    color: var(--hueDarker);
    opacity: 1;
  }

  @media (prefers-color-scheme: dark) {
    caret-color: var(--hue);

    &::placeholder {
      color: var(--hue);
    }
  }
}

ul,
ol {
  padding-left: $space;
  margin: 0 0 1em;

  p,
  ul,
  ol {
    margin-bottom: 0;
  }

  ol {
    list-style-type: lower-alpha;
  }

  li::marker {
    @include text-button;
  }

  nav & {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
}

ul {
  list-style-type: "\301c\a0";
}

dl {
  margin: 0 0 1em;

  dd {
    margin-left: 0;
  }
}

hr {
  @include rule;
}

code {
  display: inline-block;
  font-family: $sans;
  font-size: $gamma;
  background-color: var(--toneColor);
  color: var(--somberColor);
  padding: 0 0.309em;
  word-break: break-all;
  border-radius: $borderRadius;
}

abbr {
  font-family: $sans;
  font-size: $beta;
  letter-spacing: 0.03em;
  text-decoration: none;
}

noscript {
  font-family: $sans;
  color: var(--hue);
}
