// Variables
// ---------

:root {
  --lead: 32;

  --bgColor: #{$bgSol};
  --toneColor: #{$toneSol};
  --softColor: #{$softSol};
  --subtleColor: #{$subtleSol};
  --somberColor: #{$somberSol};
  --textColor: #{$textSol};

  // TODO luna definitions
  --borderColor: var(--hueDarker);
  --buttonColor: var(--hue);
  --buttonFocus: var(--hueDark);

  --articlePad: calc(#{$space} * 1.25);
  --articleSpace: calc(#{$space} * 1.75);

  --headSpace: 1.618em;
  --headSpaceLess: calc(#{$space} / 2);

  @include respond($huron) {
    --articleSpace: calc(#{$space} * 3);
  }

  @media (prefers-color-scheme: dark) {
    --bgColor: #{$bgLuna};
    --toneColor: #{$toneLuna};
    --softColor: #{$softLuna};
    --subtleColor: #{$subtleLuna};
    --somberColor: #{$somberLuna};
    --textColor: #{$textLuna};
  }
}
