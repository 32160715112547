// Components
// ----------

// Header
// --------------------------------------------------------
.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .home &,
  .erratic & {
    margin-bottom: ($space * 1.333);
  }
}

// .page-header--image {
//   // max-width: rem($headerWidth);
//   max-width: rem($contentWidth);

//   img {
//     display: block;
//     width: 100%;
//   }
// }

// nav
.tuff {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.65em;

  width: 100%;
  padding: ($space * 1.25) em($shim) 0;
  margin-bottom: ($space * 1.25);

  a,
  button {
    @include sturdy;

    display: block;
    font-size: $beta;
    line-height: $lessLineHeight;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  a:hover,
  :focus-visible,
  .is-active {
    svg {
      opacity: 1;
    }
  }

  button {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  @include respond($erie) {
    gap: 1em;
  }

  @include respond($huron) {
    grid-template-columns: 1fr auto 1fr;
  }
}

.tuff--shelf,
.tuff--slope {
  display: flex;
  gap: 0.65em;

  @include respond($erie) {
    gap: 1em;
  }
}

.tuff--shelf {
  justify-content: center;
  grid-column: 1 / span 2;

  svg {
    opacity: 0;
    transition: opacity $duration $timing;
    position: absolute;
    bottom: 100%;
  }

  @include respond($erie) {
    gap: 1em;
  }

  @include respond($huron) {
    grid-column: auto;
  }
}

.tuff--slope {
  &:first-child {
    justify-content: flex-start;
    order: 2;
  }

  &:last-child {
    order: 3;
    justify-content: flex-end;
  }

  svg {
    display: block;
    width: auto;
    height: rem(12);
  }

  @include respond($huron) {
    &:is(:first-child, :last-child) {
      order: unset;
    }
  }
}

// these are utilities to add an arrow before or after an element
// TODO reconsider weight
.is-send {
  &::after {
    content: "\00a0\2192";
    font-weight: $regular;
  }
}

.is-return {
  &::before {
    content: "\2190\00a0";
    font-weight: $regular;
  }
}

// Footer
// --------------------------------------------------------
.page-footer {
  display: flex;
  justify-content: center;

  width: 100%;
  background-color: var(--hue);
  margin-top: auto;
}

.page-footer--container {
  @include block(deify($contentWidth));

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: $space;
  padding-bottom: $space;
  position: relative;
  z-index: 100;

  p {
    font-size: $beta;
    font-family: $sans;
    font-weight: $semibold;
    line-height: $lessLineHeight;
    text-align: center;
    color: var(--bgColor);
    margin-bottom: 0;
  }

  p + p {
    padding-top: 0.65em;
  }
}

// .seal {
//   display: inline-block;
//   vertical-align: top;
//   fill: var(--bgColor);
//   margin-top: 1.25em;
// }

// Content
// --------------------------------------------------------
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.copy {
  p {
    hyphens: auto;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    @include fancy;
  }

  // this is a dead link (class is added manually)
  .is-withered {
    filter: grayscale(100%);
    opacity: 0.72;
  }

  .home & {
    p,
    ul {
      @include text-head;

      margin-bottom: em(21, 28);
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }

  @include respond($michigan) {
    p {
      hyphens: manual;
    }
  }
}

// Post
// post = on interior pages, polylith posts
// post__airy = on homepage, erratic
// post__narrow = 404, zine/success, polylith/success
// --------------------------
.post,
.post__airy,
.post__narrow {
  img {
    display: block;
    width: 100%;
  }
}

.post,
.post__airy {
  @include block;

  margin-bottom: var(--articleSpace);
}

.post {
  padding-top: var(--articlePad);
}

.post__airy {
  h2 {
    margin-bottom: em(21, 28);

    abbr {
      font-size: $gamma;
    }
  }

  [target="paypal"] {
    display: inline-block;
    line-height: 1;
  }
}

.post__narrow {
  @include block($contentWidth * 0.8);

  // text-align: center;
  margin-bottom: var(--articleSpace);
}

// h1, h2 or p
// this is a multi-line block of text on an index page (eg /, /polylith, /zine)
// .post--headlines {
//   text-align: center;
//   text-wrap: balance;
// }

.post--header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: var(--headSpace);

  &.is-null {
    display: none ;
  }
}

.post--meta {
  font-family: $sans;
  font-size: $beta;
  line-height: $snugLineHeight;
  color: var(--hue);

  margin-bottom: 0;

  time {
    display: inline;
  }

  strong {
    font-weight: $regular;
  }
}

.post--heading {
  width: 100%;
}

// figure
// image within text post
.post--image {
  @include mono;

  margin: $space em($shim * -1);

  .home & {
    margin: em(42) em($shim * -1) $space;
  }

  &:first-child {
    margin-top: 0;
  }

  img {
    // if image is missing
    // TODO why doesn't this match $toneLuna?
    background-color: var(--toneColor);
  }

  img + img {
    padding-top: ($space * 0.618);
  }

  video {
    width: 100%;
  }

  // missing image
  img::before {
    @include text-button;

    display: block;
    width: 100%;
    aspect-ratio: 1.618/1;
    color: var(--somberColor);
    padding: em($shim * 0.5) em($shim);
  }
}

// figure
// wide image within text post
.post--figurehead {
  max-width: em(1216);
  margin-bottom: $space;

  img {
    display: block;
    width: 100%;
  }

  & + .post {
    padding-top: 0;
  }
}

// figcaption
.post--caption {
  @include text-button;

  text-align: right;
  color: var(--subtleColor);

  padding-top: rem(6);
  margin: 0 rem($shim);

  p {
    margin-bottom: 0;
  }
}

:is(.post, .product) blockquote,
:is(.product) ul {
  padding: 0 1em;
  margin-bottom: 1em;

  @include respond($huron) {
    padding: 0 $space;
  }
}

:is(.post, .product) blockquote p {
  line-height: calc((var(--lead) - 1) / #{$emBase});
}

// dl
// .index {
//   display: grid;
//   grid-template-columns: auto 1fr;
//   grid-column-gap: $space;

//   text-align: left;

//   dt {
//     font-weight: $semibold;
//   }

//   dd {
//     grid-column-start: 2;
//   }
// }

// it's an inline button
.mica {
  @include button(0.1em, 0.35em);

  display: inline-block;
  font-size: $gamma;
  line-height: $lessLineHeight;

  .copy & {
    text-decoration: none;
  }
}

// it's a block-level container and button
.prism {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: $space;
}

.prism--button {
  @include button(($space * 0.618), ($space * 0.8));

  .copy & {
    text-decoration: none;
  }
}

// it's a note to take the place of redacted text
.fracture {
  max-width: rem(544);
  text-align: center;
  margin: 0 auto 1em;

  &::before,
  &::after {
    @include rule;

    display: block;
  }

  &:not(:first-child) {
    &::before {
      content: "";
    }
  }

  &:not(:last-child) {
    &::after {
      content: "";
      margin-top: 2.15em;
    }
  }
}

// // it's a meta-note about a post
// .inclusion {
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   margin: 0 auto 2em;

//   a {
//     @include text-button;
//     @include sturdy;

//     color: var(--hue);
//     text-decoration: none;
//   }
// }

// Product
// --------------------------
.product {
  @include block;

  margin-bottom: var(--articleSpace);

  img {
    display: block;
    width: 100%;
  }
}

.product--image {
  @include mono;

  margin: 0 em($shim * -1) $space;
}

.product--text {
  margin-bottom: $space;

  ul {
    font-family: $sans;
    font-size: $beta;
    line-height: $snugLineHeight;
    list-style-type: none;

    >li {
      // NB as of 250202 this works in firefox and safari
      text-indent: 0.618em hanging;
    }
  }
}

.product--header {
  margin-bottom: var(--headSpaceLess);
}

.product--form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ($space * 1.36);
  }

  .copy & {
    padding-top: 0.65em;
  }

  p {
    font-family: $sans;
    font-size: $beta;
    margin-bottom: 0.5em;
    line-height: $snugLineHeight;
  }

  form {
    width: em($formWidth);
  }

  button {
    @include button(($space * 0.618), 0);

    width: 100%;
  }
}

.product--button {
  @include button(($space * 0.618), ($space * 0.8));

  min-width: em($formWidth);

  &:only-child {
    margin-top: 0.5em;
  }
}

.product--grid {
  @include grid;

  padding-top: 0.25em;
  margin: 0 em($shim * -1);

  &:not(:last-child) {
    margin-bottom: $space;
  }
}

// Footnotes
// which can appear in post or product layouts
// --------------------------
.post,
.product {
  // this is a footnote reference; the markup is generated by kramdown
  sup {
    font-family: $sans;
    font-size: $beta;
    vertical-align: baseline;
    line-height: 1;
  }

  sup a,
  .reversefootnote {
    @include fancy(var(--hue), solid);

    font-weight: $semibold;
    padding: 0 0.25em;
  }

  // this element and its children are also generated by kramdown
  .footnotes {
    padding-top: 3em;

    ol {
      font-family: $sans;
      font-size: $beta;
      line-height: $snugLineHeight;
      color: var(--subtleColor);
    }

    // NB as of 250202, ::marker only supports color & font-size in safari
    li::marker {
      content: counter(list-item) "   ";
      font-weight: $semibold;
      color: var(--hue);
    }

    p {
      hyphens: manual;
    }
  }
}

// Divide
// --------------------------
// it's a grid of links
// .divide {
//   padding-top: $space;
//   margin-bottom: 1em;

//   ul {
//     @include grid;
//   }

//   li {
//     margin-bottom: ($space * 0.8);

//     @supports (display: grid) {
//       margin-bottom: 0;
//     }
//   }

//   a {
//     @include button($space, 0);
//   }

//   @include respond($huron) {
//     margin-bottom: 0;
//   }
// }

// Cambium
// --------------------------
// it's a form
// .cambium {
//   @include grid;
// }

// .cambium--input {
//   --borderColor: var(--hue);

//   @include form-pieces;

//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-bottom: ($space * 0.8);

//   p {
//     margin-bottom: 0;
//   }

//   label {
//     display: block;
//     width: 100%;
//     font-family: $sans;
//     font-size: $beta;
//   }

//   textarea {
//     height: calc(#{$baseLineHeight} * 5.3em);
//     line-height: $baseLineHeight;
//     padding-top: 0.4em;
//     padding-bottom: 0.4em;
//     resize: none;
//   }

//   [type=submit] {
//     &:hover {
//       color: var(--hueDarker);
//     }

//     &:focus {
//       --borderColor: var(--hue);
//     }
//   }

//   @supports (display: grid) {
//     margin-bottom: 0;
//   }

//   @include respond($huron) {
//     &.full {
//       grid-column-start: span 2;
//     }
//   }

//   // @media (prefers-color-scheme: dark) {
//   //   [type=text],
//   //   [type=email],
//   //   textarea {
//   //     &:focus {
//   //       --borderColor: #{$hslAlt};
//   //     }
//   //   }

//   //   [type=submit] {
//   //     &:hover {
//   //       color: var(--hue);
//   //     }

//   //     &:focus {
//   //       --borderColor: #{$hslAlt};
//   //     }
//   //   }
//   // }
// }

// Till
// --------------------------
// it's a grid of images with descriptions
.till {
  @include grid;

  list-style-type: none;
  padding: 0.25em 0 0;
  margin-bottom: 0;

  .copy + & {
    margin-top: ($space - 0.25em);
  }

  @include respond($victoria) {
    margin: 0 em($shim * -1);
  }

  @include respond($superior) {
    // this results in the same width as $headerWidth
    margin: 0 em($shim * -3);
  }
}

.till--item {
  a {
    @include unfancy;

    display: block;
    transition: color $duration $timing;
  }

  a:hover {
    color: var(--hue);
  }

  p {
    @include text-button;
  }
}

.till--image {
  @include mono;

  // this overrides mix-blend-mode
  // transition: opacity $duration $timing;

  // a:hover & {
  //   opacity: 0.9;
  // }
}

// TODO this isn't currently in use — remove?
// .till--placeholder {
//   aspect-ratio: 1/1;
//   background-color: var(--hue);

//   b {
//     @include text-button;

//     display: flex;
//     align-items: center;
//     justify-content: center;

//     height: 100%;
//     font-weight: normal;
//     text-align: center;
//     color: var(--bgColor);
//   }
// }

.till--heading {
  @include text-button;

  padding-top: 0.26em;
}

.till--meta {
  color: var(--hue);
}

// Sill
// --------------------------
// ul
// it's a row of buttons
// .sill {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   list-style-type: none;
//   padding-left: 0;
//   margin-bottom: $space;

//   .copy & {
//     margin-top: $space;
//   }
// }

// .sill--item {
//   a {
//     text-decoration: none;
//   }
// }

// .sill--button {
//   // redefine them here so they follow spoke hues
//   --borderColor: var(--hueDarker);
//   --buttonColor: var(--hue);
//   --buttonFocus: var(--hueDark);

//   @include button(0, 0.333em);

//   // this overrides the mixin to place box shadow outside button
//   &:focus,
//   &.is-active {
//     @include corner-fill;

//     box-shadow: 0 0 0 $borderThickness var(--hueDarker);
//   }
// }

// Erratic
// --------------------------------------------------------
// this is the animation for .wheel
@keyframes sunwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// figure
.wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space;

  margin: rem(10) 0 rem(20);

  svg,
  img {
    display: block;
    width: 100%;
    max-width: rem(160);
    aspect-ratio: 1/1;
  }

  &:is(:not(.is-record)) {
    svg {
      fill: var(--hue);
      transform: rotate(calc((-30deg * var(--spoke) + 30deg)));
    }
  }

  // animate the wheel on /polylith/welcome
  .cog & {
    animation: sunwise $durationYear linear 0s infinite;
  }

  .post__airy & {
    margin: $space 0;
  }

  @include respond($huron) {
    margin: rem(50) 0;

    svg,
    img {
      max-width: rem(200);
    }
  }
}

.erratic-grid {
  @include grid;

  padding-top: $space;
  margin: 0 em($shim * -1);

  &:not(:last-child) {
    margin-bottom: ($space * 1.5);
  }
}

.erratic-band {
  padding-top: $space;

  &:not(:last-child) {
    margin-bottom: rem(20);
  }
}

.erratic-band--heading {
  text-align: center;
  margin-bottom: 1em;

  a {
    @include fancy;
  }
}

// Erratic form
// --------------------------
.erratic-form {
  max-width: rem(544);
  background-color: var(--hue);
  padding: em(31) em(31) ($space * 0.618);
  border-radius: $borderRadius;
  margin: 0 auto;

  .copy & {
    margin-top: ($space + 1em);
  }
}

.erratic-form--container {
  position: relative;
  z-index: 100;
}

.erratic-form--input {
  // TODO limit to just the elements used
  @include form-pieces;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  & + & {
    padding-top: ($space * 0.5);
  }

  [type=email] {
    text-align: center;
  }

  [type=submit] {
    color: var(--bgColor);
  }

  // hide labels and bot trap
  label,
  &[aria-hidden=true] {
    display: none;
  }
}

// Pebble form
// --------------------------
// .pebble-form {
//   max-width: rem(544);
//   background-color: var(--hue);
//   padding: em(4) em(12) em(4) em(4);
//   border-radius: $borderRadius;

//   &:not(:last-child) {
//     margin-bottom: ($space * 2);
//   }

//   @include respond($erie) {
//     margin: 0 auto;
//   }
// }

// .pebble-form--container {
//   display: flex;

//   position: relative;
//   z-index: 100;
// }

// .pebble-form--input {
//   // TODO limit to just the elements used
//   @include form-pieces;

//   display: flex;
//   align-items: center;

//   text-align: center;

//   [type=email] {
//     text-align: center;
//   }

//   [type=submit] {
//     color: var(--bgColor);
//   }

//   &:first-child {
//     flex-grow: 1;

//     margin-right: em(10);
//   }

//   // hide labels and bot trap
//   label,
//   &[aria-hidden=true] {
//     display: none;
//   }
// }

// Strata
// --------------------------
// ul
// it's a vertical list of posts/links
.strata,
.strata__grid {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  .erratic-band & {
    margin-bottom: (1 * $alpha);
  }
}

.strata {
  &:not(:last-child) {
    margin-bottom: $space;
  }
}

.strata--item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + & {
    padding-top: 1em;

    .strata__grid & {
      padding-top: 0;
    }
  }

  &.is-faded {
    filter: grayscale(100%);
  }

  a {
    @include unfancy;

    .strata__grid & {
      display: block;
    }
  }
}

.strata--heading {
  color: var(--textColor);
  transition: color $duration $timing;

  a:hover & {
    color: var(--hue);
  }

  // a class on strata on /projects
  .is-alt & {
    line-height: $lessLineHeight;

    h3 {
      display: inline;
    }

    p {
      display: inline;
      font-size: $alpha;
      color: var(--hue);
    }
  }
}

.strata--meta {
  font-family: $sans;
  font-size: $beta;
  line-height: $snugLineHeight;
  color: var(--hue);

  .strata--heading + & {
    padding-top: em(4, 20);
  }
}

// Wheel test temporary styles
// for these to work, add "wheel-test" class to body on /polylith/wheel
// --------------------------
// .wheel-test {
//   .test-wrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   .wheel-test--wrapper {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//     gap: 4rem;
//     flex-wrap: wrap;

//     width: ((25rem * 3) + 4);
//   }

//   .wheel-test--posts {
//     @include block;

//     padding-top: 6em;

//     .post {
//       padding-top: 3em;
//     }

//     .footnote {
//       @include unfancy;
//     }
//   }

//   // imitation footer
//   .wheel-test--footer {
//     height: 6em;
//     background-color: var(--hue);
//     box-shadow: em($contentWidth * -0.92) 0 0 0 var(--hue), em($contentWidth * 0.92) 0 0 0 var(--hue), em($contentWidth * -1) 0 0 0 var(--hue), em($contentWidth * 1) 0 0 0 var(--hue);

//     text-align: center;
//     padding-top: 2em;
//     margin-top: 4em;

//     a {
//       @include unfancy;
//       @include text-button;
//       @include sturdy(var(--bgColor));
//     }
//   }

//   p a {
//     @include fancy;
//   }

//   .post__airy {
//     max-width: 100%;
//     margin-top: 1rem;
//   }

//   .copy {
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     gap: 2rem;
//   }

//   .page-header,
//   .page-footer {
//     // display: none;
//   }

//   .tuff + .wheel {
//     display: none;
//   }

//   .wheel {
//     max-width: 12.5rem;
//     margin: 0 auto 1em;
//   }

//   .strata--item {
//     display: block;
//     padding-top: 0;

//     > a {
//       display: block;
//       margin: 0.5rem 0;
//     }

//     i {
//       font-style: normal;
//       background: var(--hueLighter);
//     }
//   }

//   .strata--meta {
//     color: var(--hue);
//     margin: 0;
//   }

//   .product--button {
//     background-color: var(--hue);

//     &:hover {
//       background-color: var(--hueDark);
//     }

//     &:focus,
//     &.is-active {
//       box-shadow: inset 0 0 0 $borderThickness var(--hueDarker);
//     }
//   }
// }

// // Moon test temporary styles
// // --------------------------
// .sphere {
//   // TODO remove
//   // @include var-hues;

//   display: flex;
//   flex-wrap: wrap;
//   gap: 2rem;

//   width: ((13rem * 3) + 4);
//   margin: 2rem 0 10rem;

//   &:not(:first-child) {
//     padding-top: 8rem;
//   }
// }

// .moon {
//   width: 13rem;
// }

// .moon--meta {
//   font-family: $sans;
//   font-size: $beta;
//   line-height: $snugLineHeight;
//   color: var(--hue);
//   margin-bottom: 0;
// }

// .moon--house {
//   width: 100%;
//   aspect-ratio: 1.618/1;
//   background-color: var(--hue);

//   &.is-new {
//     aspect-ratio: 21/1;
//     text-align: center;

//     em {
//       color: var(--bgColor);
//     }
//   }
// }
