// Fonts
// -----
// Vega
// --------------------------------------------------------
// Regular
@font-face {
  font-family: "vega-webfont";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/vegaantikva-regular.woff2') format('woff2'),
       url('../fonts/vegaantikva-regular.woff') format('woff');
}

// Italic
@font-face {
  font-family: "vega-webfont";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('../fonts/vegaantikva-it.woff2') format('woff2'),
       url('../fonts/vegaantikva-it.woff') format('woff');
}

// Source Sans Pro
// --------------------------------------------------------
// Regular
@font-face {
  font-family: "source-sans-webfont";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('SourceSans3-Regular'),
       url('../fonts/sourcesans-regular.woff2') format('woff2'),
       url('../fonts/sourcesans-regular.woff') format('woff');
}

// Italic
@font-face {
  font-family: "source-sans-webfont";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: local('SourceSans3-It'),
       url('../fonts/sourcesans-it.woff2') format('woff2'),
       url('../fonts/sourcesans-it.woff') format('woff');
}

// Semibold
@font-face {
  font-family: "source-sans-webfont";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('SourceSans3-Semibold'),
       url('../fonts/sourcesans-semibold.woff2') format('woff2'),
       url('../fonts/sourcesans-semibold.woff') format('woff');
}

// Semibold Italic
@font-face {
  font-family: "source-sans-webfont";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: local('SourceSans3-SemiboldIt'),
       url('../fonts/sourcesans-semibold-it.woff2') format('woff2'),
       url('../fonts/sourcesans-semibold-it.woff') format('woff');
}
